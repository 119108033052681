import React from 'react';
import { ContactFormHeroFullPage } from '../../components/enfusion/ContactFormHeroFullPage';

function ACFContactFormHeroFullPage({ meta, data, className, anchor, bid }) {
  const {
    top_heading,
    page_heading,
    description,
    style_accent_color,
    desktop_image,
    // style_layout,
    form_url,
  } = data;
  let bg = meta?.images[desktop_image];
  console.log(data)
  // make sure line breaks are shown in the description - need to replace the line break characters with <br> tags
  // do not replace the line breaks immediately preceding or following a <ul> or <li> tag
  // const newDescription = description.replace(/(?<!<\/?(ul|li)>)\r\n(?!<\/?(ul|li)>)/g, '<br>');
  // can't use the above since it uses negative lookahead/lookbehind, which is not supported in Safari

  function processNonListText(text) {
    return text.replace(/\r\n/g, '<br>');
  }
  
  const listRegex = /<ul>[\s\S]*?<\/ul>/g;
  let remainder = description;
  let newDescription = '';
  let match;

  // while ((match = listRegex.exec(description)) !== null) {
  //   const nonListText = remainder.slice(0, match.index);
  //   newDescription += processNonListText(nonListText);
  //   newDescription += match[0]; // add the matched list back in, unchanged
  //   remainder = remainder.slice(match.index + match[0].length);
  // }

  // newDescription += processNonListText(remainder); // process any remaining non-list text

  // // Now remove any <br> that directly follow a <ul> or directly precede a </ul>
  // newDescription = newDescription.replace(/<br><ul>/g, '<ul>').replace(/<\/ul><br>/g, '</ul>');
  // newDescription = newDescription.replace(/\u2028/g, ''); // remove any unicode line breaks

  return (
    <ContactFormHeroFullPage
      className={className}
      anchor={anchor}
      top_heading={top_heading}
      page_heading={page_heading}
      form_url={form_url}
      bg={bg}
      // testimonials_carousel={data?.testimonials_carousel}
      // testimonials={meta?.testimonials}
      bid={bid}
      accent={style_accent_color}
      // layout={style_layout}
    />
  );
}

export default ACFContactFormHeroFullPage;