import React, { useState, useEffect } from 'react';
// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper';
import NextNavLink from '../../util/NextNavLink';
import { Transition } from '@headlessui/react';

import { useAppContext } from '../../../util/context';
import { useWindowDimensions } from '../../../util/hooks';

    import { getCookie, setCookie } from '../../../util/auth';
import { getAbsoluteUrl } from '../../../lib/helpers'
import { EmailFormHero } from '../EmailFormHero';

export const FullHeightHero = ({
    className = '',
    anchor = '',
    bg,
    mobileBg,
    top_heading,
    main_heading_1,
    main_heading_2,
    description,
    text_area,
    background_image,
    background_pattern,
    style_dark_light,
    headingIcon,
    cta,
    enable_hero_form,
    wide = '0',
    scrollto = '#',
    image_is_background,
    scroll_arrow,
    pardot_link
}) => {
    const context = useAppContext();
   const [isMobile, setIsMobile] = useState();
    const [modalState, setModalState] = useState(false);
 
    context.toggleDarkMode(style_dark_light !== '1' ? false : true);
    context.toggleHeaderTheme('');
    
    useEffect(() => {
       function handleBackground() {
            setIsMobile(useWindowDimensions().width < 769 ? true : false);
        }

        window.addEventListener('resize', handleBackground);
        window.addEventListener('load', handleBackground);

        return () => {
            window.removeEventListener('resize', handleBackground);
            window.removeEventListener('load', handleBackground);
        };
    }, [useWindowDimensions().width]);
    let iframeURL = cta.url;
    try {
         iframeURL = new URL(cta?.url || 'http://place.hold');
    
        if (typeof window !== 'undefined') {
            const uparams = new URLSearchParams(window.location.search);
            if (uparams.has('gclid')) {
                setCookie('gclid', uparams.get('gclid'));
                iframeURL.searchParams.set('gclid', uparams.get('gclid'));
            } else {
                if (getCookie('gclid')) {
                    iframeURL.searchParams.set('gclid', getCookie('gclid'));
                }
            }
            ['source', 'content', 'medium','campaign'].forEach((type) => {
                if (uparams.has(`utm_${type}`)) {
                    setCookie(`utm_${type}`, uparams.get(`utm_${type}`));
                    iframeURL.searchParams.set(
                        `utm_${type}`,
                        uparams.get(`utm_${type}`)
                    );
                } else if (getCookie(`utm_${type}`)) {
                    iframeURL.searchParams.set(
                        `utm_${type}`,
                        getCookie(`utm_${type}`)
                    );
                }
            });
        }

    } catch (er) {
        console.error(er)
    }
 const reducedH1Style = {
         fontSize: '14px', // Adjust the size as needed
        lineHeight: '1', // Adjust the line height as needed
        letterSpacing:'2.5px',
    };
    return (
        <>
        <div
            className={`${className} relative`}
            id={anchor}
        >
            <div
                className={`flex items-center bg-cover w-full overflow-hidden relative md:min-h-[800px] md:bg-center bg-right ${
                    background_image?.class ? background_image?.class : 'bg-gray-19'
                    }`}
                style={image_is_background === '1' ? { backgroundImage: `url(${isMobile ? mobileBg?.src || bg?.src : bg?.src})` } : {}}
               
            >
                <div className="container relative z-[1] md:h-auto h-full md:mt-6">
                    <div className="grid grid-cols-1 gap-10 md:grid-cols-12 md:gap-0 md:py-0 py-28">
                        <div className={`md:col-span-6 ${headingIcon?.class ? '' : 'lg:pr-[4%]'} wow fadeInLeft`}>
                            <div className="flex items-center h-full">
                                <div>
                                    
                                    {headingIcon && <i className={`${headingIcon?.class} text-40 text-purple block mb-7`}></i>}
                                    {top_heading && (
                                        <p
                                            className={`flex items-center mb-5 font-medium ${headingIcon?.class ? 'md:mb-7' : 'md:mb-8'} label ${
                                                style_dark_light === '1'
                                                    ? 'text-white'
                                                    : 'text-purple-midnight'
                                            }`}
                                        >
                                            {!headingIcon?.class && 
                                                <i className="inline-block w-3 h-3 mr-3 border-2 rounded-10 border-purple"></i>
                                            }
                                            <h1 className='text-sm full-hieght-hero' style={reducedH1Style}>{top_heading}</h1>
                                        </p>
                                    )}
                                
                                    <h2 className={`mb-5 md:h3 h1 ${headingIcon?.class || wide !== '1' ? 'md:mb-7' : 'md:mb-8 lg:h2'} `}>
                                        {main_heading_1 && (
                                            <span
                                                className={`${
                                                    style_dark_light === '1'
                                                        ? 'text-white'
                                                        : 'text-purple-midnight'
                                                    }`}
                                                dangerouslySetInnerHTML={{ __html: main_heading_1}}
                                        />
                                        )}

                                        {main_heading_2 && (
                                            <>
                                            <br />
                                            <span
                                                className={`${
                                                    style_dark_light === '1'
                                                        ? 'text-purple'
                                                        : 'text-purple'
                                                    }`}
                                                    dangerouslySetInnerHTML={{ __html: main_heading_2}}
                                        />
                                        </>
                                        )}
                                    </h2>
                                    <div
                                        className={`lead lead--large opacity-70  ${
                                            style_dark_light === '1'
                                                ? 'text-white'
                                                : 'text-purple-midnight'
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: description
                                        }}
                                    />
                                        {enable_hero_form ? (
                                            <EmailFormHero />
                                        ) : (
                                            <>
                                                {cta?.url && (
                                                    <NextNavLink
                                                        href={cta?.url}
                                                        target={cta?.target}
                                                    >
                                                        <a
                                                            onClick={(e) => {
                                                                if (
                                                                    pardot_link ===
                                                                        'Full screen popup' ||
                                                                    pardot_link ===
                                                                        'Normal popup'
                                                                ) {
                                                                    e.preventDefault();
                                                                    setModalState(
                                                                        true
                                                                    );
                                                                } else {
                                                                    return;
                                                                }
                                                            }}
                                                            className="inline-block mt-6 md:mt-10 btn btn--primary"
                                                            dangerouslySetInnerHTML={{
                                                                __html: cta?.title
                                                            }}
                                                        ></a>
                                                    </NextNavLink>
                                                )}
                                            </>
                                        )}
                                    {/* {cta?.url && (
                                            <NextNavLink href={cta?.url} target={cta?.target}>
                                                <a onClick={(e) => {
                                                    
                                                      if(pardot_link === 'Full screen popup' || pardot_link === 'Normal popup') {
                                                            e.preventDefault()
                                                            setModalState(true)
                                                      } else {
                                                          return
                                                    }
                                                }
                                                } className="inline-block mt-6 md:mt-10 btn btn--primary" dangerouslySetInnerHTML={{ __html: cta?.title }} ></a></NextNavLink>)
                                    } */}
                                </div>
                            </div>
                        </div>
                        <div className="md:col-span-6 flex md:flex-row-reverse items-center">
                            {text_area && (
                                <div
                                className={`lead lead--large opacity-70 max-w-[75%] ${
                                            style_dark_light === '1'
                                                ? 'text-white'
                                                : 'text-purple-midnight'
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: text_area
                                        }}
                                    />
                            )}
                            {image_is_background !== '1' && (
                                <>
                                {wide !== '1' ?
                                    <div className="lg:pl-28">
                                        <img
                                            src={bg?.src}
                                            alt={bg?.title || 'Enfusion'}
                                            className="w-full "
                                        />
                                    </div>
                                :
                                <div
                                    className={`relative flex items-center justify-center  min-h-full md:left-auto md:w-auto md:static ${
                                        bg?.src ? 'w-screen' : ''
                                    }`}
                                >
                                    {bg?.src && (
                                        <div className="md:absolute w-screen transform md:max-w-[50vw] md:left-1/2 md:translate-x-[6.5vw] md:pr-[6.33vw] md:pl-6 md:right-auto relative">
                                            <img
                                                src={bg?.src}
                                                alt={bg?.title || 'Enfusion'}
                                                className={`w-full ${mobileBg?.src ? 'md:block hidden' : ''}`}
                                            />
                                            {
                                                mobileBg?.src &&
                                                (<img
                                                src={mobileBg?.src}
                                                alt={mobileBg?.title || 'Enfusion'}
                                                className={`w-full ${mobileBg?.src ? 'md:hidden' : ''}`}
                                                />)
                                            }
                                        </div>
                                    )}
                                </div>
                                }
                            </>)}
                         
                        </div>
                    </div>
                </div>
                {background_pattern?.class && (
                    <div
                        className={`${background_pattern?.class} max-w-[1500px] w-full h-full md:max-h-[670px] max-h-[570px] bg-cover -bottom-1/3 md:-right-1/3 -right-12 absolute z-0`}
                    ></div>
                )}
            </div>

            <div className="sticky bottom-0 left-0 z-10 flex items-center justify-between w-full">
                {/* <div className="absolute z-[2] transform rotate-90 -translate-x-1/2 swipte-down-arrow left-1/2 bottom-12">
                    <a
                        href={getAbsoluteUrl(scrollto)}
                        className="flex items-center font-medium lead icon-circle"
                    >
                        {(scroll_arrow === '1' || typeof scroll_arrow === 'undefined') && (
                            <span className="relative flex items-center h-4 ml-4">
                                <i
                                className={`inline-block en-long-arrow text-32 leading-18 relative z-[1] transition ${
                                    context?.dark ? 'text-white' : 'text-purple-midnight'
                                }`}
                                ></i>

                                <svg
                                width="32"
                                height="32"
                                className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__base"
                                ></circle>
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    style={{
                                    stroke: context?.dark ? '#fff' : '#1E1A34'
                                    }}
                                    className="icon-circle__fill"
                                ></circle>
                                </svg>
                            </span>
                            )}
                    </a>
                </div> */}
            </div>
        </div>
        {pardot_link && (
                <Transition
                    className={`relative z-[100] ${
                        pardot_link === 'Normal popup'
                            ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                            : ''
                    }`}
                    show={modalState}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        className={` z-[99] ${
                            pardot_link === 'Normal popup'
                                ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                                : 'fixed top-0 left-0 w-full h-full min-h-screen'
                        }`}
                    >
                        <div className="absolute top-0 right-0 z-[1] p-4">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalState(false);
                                }}
                                className="flex items-center font-medium lead text-purple-midnight"
                            >
                                <span className="min-w-[42px]">Close</span>
                                <span
                                    className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                </span>
                            </a>
                        </div>
                        <iframe
                            id="pardot-iframe"
                            src={iframeURL}
                            className="w-full h-full bg-white"
                        ></iframe>
                    </div>
                    {pardot_link === 'Normal popup' ? (
                        <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                    ) : (
                        ''
                    )}
                </Transition>
            )}
            </>
    );
};
export default FullHeightHero;
