import React, { useState, useEffect, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import TestimonialCard from '../TestimonialCard';
import { sendUTMsToIframe } from '../../../util/send-utms-to-iframe';
import { useAppContext } from '../../../util/context';
import useBetterMediaQuery from '../../../util/better-media-query';

// custom hook for calculating distance to bottom
function useDistanceToBottom(ref) {
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const calculateDistance = () => {
      const rect = ref.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop + rect.height;
    };

    const updateDistance = () => {
      if (ref.current)
        setDistance(calculateDistance());
    };

    updateDistance();

    window.addEventListener('resize', updateDistance);

    return () => {
      window.removeEventListener('resize', updateDistance);
    };
  }, [ref]);

  return distance;
}


export const ContactFormHeroFullPage = ({
  className = '',
  anchor = '',
  top_heading,
  page_heading,
  description,
  form_url,
  bg,
  testimonials_carousel,
  testimonials = [],
  bid,
  accent,
  layout,
}) => {

  const isMobile = useBetterMediaQuery('(max-width: 1023px)');
  const elementRef = useRef(null);
  const distanceToBottom = useDistanceToBottom(elementRef);
  const context = useAppContext();

  useEffect(() => {
    // context.toggleDarkMode(false);
    //   document.body.classList.add("form-open"); // Add class to body
    // document.body.style.overflow = "hidden"; // Disable scrolling
  }, [context]);

  useEffect(() => {
    if (form_url) {
      // document.body.classList.add("form-open"); // Add class to body
      // document.body.style.overflow = "hidden"; // Disable scrolling

      // Hide first header and last footer
      const firstHeader = document.querySelector("header:first-of-type");
      const lastFooter = document.querySelector("footer:last-of-type");

      if (firstHeader) firstHeader.style.display = "none";
      if (lastFooter) lastFooter.style.display = "none";
    }

    return () => {
      document.body.classList.remove("form-open"); // Remove class when unmounting
      document.body.style.overflow = ""; // Restore scrolling

      // Show header and footer again
      const firstHeader = document.querySelector("header:first-of-type");
      const lastFooter = document.querySelector("footer:last-of-type");

      if (firstHeader) firstHeader.style.display = "";
      if (lastFooter) lastFooter.style.display = "";
    };
  }, [form_url]); // Runs when form_url is available

  const layoutStyleDefs = {
    '1': { //"Contact"
      headerBgStyles: {
        // backgroundSize: `100% ${distanceToBottom}px`,
        // backgroundPosition: 'top',
        backgroundImage: `url(${bg?.src})`
      },
      h1Class: 'md:h2',
      contentClass: 'pb-24 lg:pb-16',
      formClass: ' cstm-pardot-form showform',
      cardClass: 'shadow bg-opacity-60',
    },
    '2': { //"Demo"
      headerBgStyles: {
        backgroundSize: `${isMobile ? 'contain' : '100% 360px'}`,
        backgroundPosition: 'bottom',
        backgroundImage: `url(${bg?.src})`
      },
      h1Class: 'md:h3',
      contentClass: 'xl:px-8',
      formClass: 'cstm-pardot-form',
      cardClass: 'bg-opacity-60',
    },
    'default': {
      headerBgStyles: {
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      h1Class: 'md:h2',
      contentClass: '',
      formClass: '',
      cardClass: '',
    },
  };

  const { headerBgStyles, h1Class, contentClass, formClass, cardClass } = layoutStyleDefs["1"] || layoutStyleDefs['default'];

  let first_testimonial = null;

  if (testimonials_carousel?.length > 0) {
    const first_testimonial_id = testimonials_carousel[0]; // get the first item
    first_testimonial = testimonials.find(item => String(item.id) === first_testimonial_id);
  }

  sendUTMsToIframe();

  function openForm() {
    document.body.style.overflow = "hidden"; // Disable scrolling
    document.querySelector(".cstm-pardot-form").classList.add("showform");
  }

  function closeForm() {
    document.body.style.overflow = ""; // Restore scrolling
    document.querySelector(".cstm-pardot-form").classList.remove("showform");

    // Restore header and footer visibility
    const firstHeader = document.querySelector("header:not(.contact-form-hero)");
    const lastFooter = document.querySelector("footer:last-of-type");

    if (firstHeader) firstHeader.style.display = "";
    if (lastFooter) lastFooter.style.display = "";
  }

  // console.log(form_url);
  return (

    <header
      className={`wow fadeInUp flex items-center justify-center h-screen w-full bg-no-repeat bg-cover bg-center contact-form-hero contact-form-hero--layout-${layout} ${className}`}
      id={anchor}
      data-name="contact-form-hero"
      data-accent={`${accent}`}
      style={{ ...headerBgStyles }}
    >
      <div className="container spz-2002 w-full flex justify-center items-center">
        {form_url && (
          <div className="cstm-pardot-form showform">
            <span className="close-icon" onClick={closeForm}>✖</span>
            <div className="cstm-form-inner">
              <div className="cstm-form-flex">
                <div className="CUSTOM-FORM">
                  <div className="shadow">
                    <IframeResizer
                      src={form_url}
                      id="pardot-iframe"
                      width="100%"
                      allowtransparency="true"
                      sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>

  );
};
export default ContactFormHeroFullPage;
