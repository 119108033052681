import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { SingleColAccordianList } from '../../components/enfusion/SingleColAccordianList';

function ACFSingleColAccordianList({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const { top_heading, description, featured_video, featured_image } = data;
    let lists = extractRepeaterField(data, 'accordion_list');
    

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        featured_image: meta?.images[repeaterItem.featured_image] || {}
    }));

    return (
        <SingleColAccordianList
            className={className}
            anchor={anchor}
            lists={lists}
            top_heading={top_heading}
            description={description}
        />
    );
}

export default ACFSingleColAccordianList;
