// import { invalidDomains } from '@util/constants';
// import { setCookies } from '@util/set-cookies';
import { useRouter } from 'next/router';
import React from 'react';

export const EmailFormHero = ({}) => {
    const router = useRouter();
    function validateEmail(email) {
        // Regular expression for basic email validation
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // Extract domain from email address
        const domain = email.split('@')[1];
        // // Check if email matches the regex and if its domain is not in the invalidDomains array
        if (emailRegex.test(email) && !invalidDomains.includes(`@${domain}`)) {
            return true;
        }
        return false;
    }

    const handleFormValidationandSubmission = (value) => {
        if (!validateEmail(value)) {
            document
                .querySelector('.spz-email-wrapper .form-inner')
                .classList.add('isError');
        } else {
            document
                .querySelector('.spz-email-wrapper .form-inner')
                .classList.remove('isError');
            setCookies({ name: 'useremail', value: value });
            router.push('/get-a-demo');
        }
    };

    const submitForm = (ev) => {
        ev.preventDefault();
        const field = ev.target.emailaddress.value.trim();
        if (field) {
            handleFormValidationandSubmission(field);
        } else {
            router.push('/get-a-demo');
        }
    };

    return (
        <div className="spz-homepage">
            <div className="spz-email-wrapper">
                <form id="customform" onSubmit={submitForm}>
                    <div className="form-inner">
                        <div className="floating-label">
                            <input
                                name="emailaddress"
                                placeholder="Enter your email"
                                type="text"
                                autoComplete="off"
                                onChange={(e) => {
                                    let value = e.currentTarget.value.trim();
                                    if (!validateEmail(value)) {
                                        document
                                            .querySelector(
                                                '.spz-email-wrapper .form-inner'
                                            )
                                            .classList.add('isError');
                                    } else {
                                        document
                                            .querySelector(
                                                '.spz-email-wrapper .form-inner'
                                            )
                                            .classList.remove('isError');
                                    }
                                }}
                                className="floating-input"
                                data-gaconnector-tracked="true"
                            />
                        </div>
                        <button type="submit">
                            <span> Get Started</span>
                        </button>
                    </div>
                    <p id="customerrormessage">
                        Please provide a valid company email address.
                    </p>
                </form>
            </div>
        </div>
    );
};
