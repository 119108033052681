import React, { useEffect, useState } from 'react';
import NextNavLink from '@cutil/NextNavLink';
import { getAbsoluteUrl } from '@lib/helpers';
import ReactPlayer from 'react-player';
import Fancybox from './fancybox';

export const SingleColAccordianList = ({
  className = '',
  anchor = '',
  lists,
  top_heading,
  description
}) => {
  const [disclosureState, setDisclosureState] = useState(null);
  const [itemClass, setItemClass] = useState('careers-sec-accordion');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const defaultClass = 'careers-sec-accordion'; // Define the default class

  const toggle = (index, class_name) => {
    if (disclosureState === index) {
      // If the item is already open, close it and revert to the default class
      setItemClass(defaultClass);
      setDisclosureState(null);
    } else {
      // Otherwise, open the item and set the class to the provided class_name
      setItemClass(class_name ?? defaultClass);
      setDisclosureState(index);
    }
  };


  const CreateList = ({ list }) => {
    const col1 = [];
    const col2 = [];

    list.forEach((item, index) => {
      if (index % 2 === 0) {
        col1.push(item);
      } else {
        col2.push(item);
      }
    });

    const newList = [col1, col2];

    return (
      <>
        {newList.map((col, i) => (
          <div className="" key={i}>
            {col.map((item, index) => {
              const uniqueId = `list-item-${i}-${index}`;

              return (
                <div
                  className="border-purple-midnight last:border-b border-opacity-10 wow"
                  key={uniqueId}
                  id={uniqueId}
                >
                  <div
                    className="flex items-center py-6 border-t cursor-pointer border-purple-midnight lead md:lead--large border-opacity-10"
                    onClick={() => {
                      toggle(uniqueId, item?.background_class_name);
                    }}
                  >
                    <div className="flex w-full items-center careers-sec-accordion-title">
                      {item.svg_icon ? (
                        <span
                          className="mr-6 lg:mr-8 text-24 text-purple"
                          dangerouslySetInnerHTML={{
                            __html: item.svg_icon,
                          }}
                        />
                      ) : (
                        <i className="mr-6 lg:mr-8 en-circle-check text-24 text-purple"></i>
                      )}

                      <span
                        className="flex flex-1 lead lead--large"
                        dangerouslySetInnerHTML={{
                          __html: item?.main_heading,
                        }}
                      />
                    </div>
                    <i
                      className={`en-caret-down opacity-30 inline-block transform text-14 ${disclosureState === uniqueId ? 'rotate-180' : ''
                        }`}
                    ></i>
                  </div>
                  <div
                    className={`accordion-content ${disclosureState === uniqueId ? 'open' : ''
                      } pb-6 pl-12 lg:pl-14`}
                  >
                    <div
                      className="lead opacity-70"
                      dangerouslySetInnerHTML={{
                        __html: item?.content,
                      }}
                    />
                    <div className='my-2 -ml-[20px]'>
                      {isClient && item.featured_video && (
                        <div className="card-image" >
                          {!!item?.featured_image?.src ? (
                            <img
                              src={item?.featured_image.src}
                              alt="Card image"
                              style={{ borderRadius: '10px' }}
                            />
                          ) : (
                            <img src="/images/enfusion-placeholder.png" alt="Placeholder image" style={{ borderRadius: '10px' }} />
                          )}
                          {!!item.featured_video && (
                            <Fancybox>
                              <div className="w-full absolute top-[100px]">
                                <img
                                  className="mx-auto cursor-pointer"
                                  src="/images/play-alternate.svg"
                                  alt="Play"
                                  style={{
                                    position: 'static',
                                    height: '59px',
                                    width: '59px'
                                  }}
                                ></img>
                              </div>
                              <a
                                className="absolute w-full h-full flex items-end justify-start top-0 left-0 cursor-pointer text-white"
                                data-fancybox="video-gallery"
                                rel="gallery01"
                                href={item.featured_video}
                              ></a>
                            </Fancybox>
                          )}
                        </div>
                      )}
                    </div>
                    {item?.cta?.url && (
                      <NextNavLink href={getAbsoluteUrl(item.cta?.url)} target="_blank">
                        <a
                          data-wow-delay={`${1 / 4}s`}
                          className="flex items-end justify-end py-4 leading-20 wow fadeInUp text-purple-midnight group hover:text-purple"
                          key={uniqueId}
                        >
                          <i className="transition en-long-arrow text-20 group-hover:text-white group-hover:opacity-100"></i>
                        </a>
                      </NextNavLink>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={`pb-32 pt-32 overflow-hidden relative wow fadeInUp ${className} ${itemClass}`}
      id={anchor}
    >
      <div className="container relative z-[1]">
        <div className="md:pr-28">
          {top_heading && (
            <h2 className="text-center font-semibold text-20 md:text-24 leading-32 py-[15px]">
              {top_heading}
            </h2>
          )}
          <div
            className="p text-center font-normal text-12 md:text-16 w-5/6 mx-auto leading-[35px] py-[10px] pb-16"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </div>
        <div className="grid lg:gap-4 lg:grid-cols-2">
          {lists && <CreateList list={lists} />}
        </div>
      </div>
    </div>
  );
};

export default SingleColAccordianList;
